import { LatLngTuple } from "leaflet";

export class Location {
  id: number;
  name: string;
  position: LatLngTuple;
  label?: string;

  constructor(id: number, name: string, position: LatLngTuple, label?: string) {
    this.id = id;
    this.name = name;
    this.position = position;
    this.label = label;
  }
}

export class Cluster {
  name: string;
  center: LatLngTuple;
  radius: number;
  locations: Location[];
  constructor(
    name: string,
    center: LatLngTuple,
    radius: number,
    locations?: Location[]
  ) {
    this.name = name;
    this.center = center;
    this.radius = radius;
    this.locations = locations || [];
  }
}
