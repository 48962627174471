import AddLocationIcon from "@mui/icons-material/AddLocation";
import { IconButton, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

const LocationTextfield = ({
  addLocation,
}: {
  addLocation: (name: string, lattitude: string, longitude: string) => void;
}) => {
  const [input, setInput] = useState("");
  const processInput = (input: string) => {
    const [name, lattitude, longitude] = input.split(",");
    return { name, lattitude, longitude };
  };

  const SearchButton = () => (
    <IconButton
      onClick={() => {
        console.log(processInput(input));
        const { name, lattitude, longitude } = processInput(input);
        addLocation(name, lattitude, longitude);
      }}
    >
      <AddLocationIcon />
    </IconButton>
  );

  return (
    <TextField
      id="outlined-basic"
      label="Name, Lattitude, Longitude"
      variant="outlined"
      size="small"
      fullWidth
      margin="normal"
      InputProps={{ endAdornment: <SearchButton /> }}
      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setInput(e.target.value);
      }}
    />
  );
};

export default LocationTextfield;
