import { Button } from "@mui/material";

import { postLocations } from "../api/LocationsAPI";
import { Cluster, Location } from "../types";
import LocationList from "./LocationList";
import AddLocationBar from "./LocationTextfield";
import NumberOfDaysTextfield from "./NumberOfDaysTextfield";
const SideBar = ({
  addLocation,
  clearLocations,
  addClusters,
  setNumberOfDays,
  locations,
  clusters,
  numberOfDays,
}: {
  addLocation: (name: string, lattitude: string, longitude: string) => void;
  clearLocations: () => void;
  addClusters: (locations: Location[]) => void;
  setNumberOfDays: (numberOfDays: number) => void;
  locations: Location[];
  clusters: Cluster[];
  numberOfDays: number;
}) => {
  const handleOptimise = () => {
    postLocations(locations, numberOfDays).then((response) => {
      if (!response) {
        console.log("error");
        return;
      }
      addClusters(response.data);
    });
  };
  return (
    <div className="bg-zinc-300 p-4 opacity-90 w-1/5 md:w-1/4 h-full  absolute z-50 right-0 top-0">
      <LocationList
        clearLocations={clearLocations}
        locations={locations}
        clusters={clusters}
      />
      <AddLocationBar addLocation={addLocation} />
      <NumberOfDaysTextfield setNumberOfDays={setNumberOfDays} />
      <Button
        variant="contained"
        fullWidth
        onClick={handleOptimise}
        disabled={locations.length === 0}
      >
        Optimise
      </Button>
    </div>
  );
};

export default SideBar;
