import { latLngBounds, LatLngTuple } from "leaflet";

import { Location } from "../types";

const initCenter: LatLngTuple = [35.6315589, 139.7066713];
export const getCenter = (locations: Location[]) => {
  if (locations.length === 0) {
    return initCenter;
  }

  const { minLat, maxLat } = getMinMaxLat(locations);
  const { minLng, maxLng } = getMinMaxLng(locations);
  const centerLat = (minLat + maxLat) / 2;
  const centerLng = (minLng + maxLng) / 2;
  const center: LatLngTuple = [centerLat, centerLng];
  return center;
};

export const getRadius = (locations: Location[]) => {
  if (locations.length === 0) {
    return 400;
  } else if (locations.length === 1) {
    return 6000;
  }
  const { minLat, maxLat } = getMinMaxLat(locations);
  const { minLng, maxLng } = getMinMaxLng(locations);

  return Math.max(maxLat - minLat, maxLng - minLng) * 111139;
};

export const getBounds = (locations: Location[]) => {
  if (locations.length === 0) {
    return latLngBounds(initCenter, initCenter);
  }
  const { minLat, maxLat } = getMinMaxLat(locations);
  const { minLng, maxLng } = getMinMaxLng(locations);

  return latLngBounds(
    [minLat - 0.2, minLng - 0.2],
    [maxLat + 0.2, maxLng + 0.2]
  );
};

const getMinMaxLat = (locations: Location[]) => {
  const latitudes = locations.map((location) => location.position[0]);
  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);

  return { minLat, maxLat };
};

const getMinMaxLng = (locations: Location[]) => {
  const longitudes = locations.map((location) => location.position[1]);
  const minLng = Math.min(...longitudes);
  const maxLng = Math.max(...longitudes);

  return { minLng, maxLng };
};
