import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

const NumberOfDaysTextfield = ({
  setNumberOfDays,
}: {
  setNumberOfDays: (numberOfDays: number) => void;
}) => {
  return (
    <TextField
      id="outlined-basic"
      label="Number of Days"
      variant="outlined"
      size="small"
      fullWidth
      margin="normal"
      type="number"
      onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setNumberOfDays(Number(e.target.value));
      }}
      defaultValue={1}
    />
  );
};

export default NumberOfDaysTextfield;
