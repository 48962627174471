import "leaflet/dist/leaflet.css";
import "./Map.css";

import { LatLngTuple } from "leaflet";
import L from "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import { useEffect, useState } from "react";
import {
  Circle,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";

import { getClusters } from "../utils/clusterHelper";
import { getBounds, getCenter } from "../utils/locationHelper";
import { Cluster, Location } from "./../types";
import SideBar from "./SideBar";

const Map = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [numberOfDays, setNumberOfDays] = useState<number>(1);
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const center: LatLngTuple = getCenter(locations);
  setMarkerIcon();

  const addLocation = (name: string, lattitude: string, longitude: string) => {
    setLocations([
      ...locations,
      new Location(locations.length, name, [
        Number(lattitude),
        Number(longitude),
      ]),
    ]);
  };

  const clearLocations = () => {
    setLocations([]);
  };

  const addClusters = (locations: Location[]) => {
    const newClusters = getClusters(locations);
    setClusters(newClusters);
  };

  useEffect(() => {
    setClusters([]);
  }, [locations]);

  const FlyMapTo = () => {
    const map = useMap();
    useEffect(() => {
      map.flyToBounds(getBounds(locations));
    }, [map]);
    return null;
  };

  return (
    <div id="map-container" className="relative">
      <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locations.map((location, index) => {
          return (
            <Marker key={index} position={location.position}>
              <Popup>
                <span>{location.name}</span>
              </Popup>
            </Marker>
          );
        })}

        {clusters.map((cluster, index) => {
          return (
            <Circle
              key={index}
              center={cluster.center}
              pathOptions={{ fillColor: "red" }}
              radius={cluster.radius}
            />
          );
        })}

        <FlyMapTo />
      </MapContainer>
      <SideBar
        addLocation={addLocation}
        clearLocations={clearLocations}
        addClusters={addClusters}
        setNumberOfDays={setNumberOfDays}
        locations={locations}
        clusters={clusters}
        numberOfDays={numberOfDays}
      />
    </div>
  );
};

const setMarkerIcon = () => {
  const DefaultIcon = new L.Icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
    shadowUrl: iconShadow,
  });

  L.Marker.prototype.options.icon = DefaultIcon;
};

export default Map;
