import { LatLngTuple } from "leaflet";

import { Cluster, Location } from "../types";
import { getCenter, getRadius } from "./locationHelper";

export const getClusters = (locations: Location[]) => {
  const clustered_locations = locations.reduce(function (r, location) {
    if (location.label === undefined) return r;

    r[location.label] = r[location.label] || [];
    r[location.label].push(location);
    return r;
  }, Object.create(null));

  const newClusters = Object.keys(clustered_locations).map<Cluster>(function (
    key,
    index
  ) {
    const center: LatLngTuple = getCenter(clustered_locations[key]);
    const radius: number = getRadius(clustered_locations[key]);
    return new Cluster(
      `Day ${index + 1}`,
      center,
      radius,
      clustered_locations[key]
    );
  });

  return newClusters;
};
