import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GrainIcon from "@mui/icons-material/Grain";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { useState } from "react";

import { Cluster, Location } from "../types";
const LocationList = ({
  clearLocations,
  locations,
  clusters,
}: {
  clearLocations: () => void;
  locations: Location[];
  clusters: Cluster[];
}) => {
  const [open, setOpen] = useState<{ [index: number]: boolean }>({});
  const handleClick = (index: number) => {
    setOpen({ ...open, [index]: !open[index] });
  };
  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          className="flex justify-between"
        >
          <p>Locations</p>
          <Button sx={{ width: 20 }} onClick={clearLocations}>
            Clear
          </Button>
        </ListSubheader>
      }
    >
      {clusters.length > 0
        ? clusters.map((cluster, index) => {
            return (
              <>
                <ListItemButton key={index} onClick={() => handleClick(index)}>
                  <ListItemIcon>
                    <GrainIcon />
                  </ListItemIcon>
                  <ListItemText primary={cluster.name} />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  key={index}
                  in={open[index]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {cluster.locations.map((location, index) => {
                      return (
                        <ListItemButton key={index} sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <LocationOnIcon />
                          </ListItemIcon>
                          <ListItemText primary={location.name} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            );
          })
        : locations.map((location, index) => {
            return (
              <ListItemButton key={index}>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary={location.name} />
              </ListItemButton>
            );
          })}
    </List>
  );
};

export default LocationList;
