import axios from "axios";

import { Location } from "../types";

export const postLocations = async (
  locations: Location[],
  numberOfDays: number
) => {
  const locations_json = JSON.stringify(locations);
  const url = process.env.REACT_APP_FUNCTION_URL || "http://localhost:8000";
  console.log(`"post locations: ${locations_json} to ${url}"`);
  return axios
    .post<Location[]>(`${url}/cluster/`, locations_json, {
      headers: {
        "Content-Type": "application/json",
      },
      params: { n_clusters: numberOfDays },
    })
    .catch(function (error) {
      console.log(error);
    });
};
